<template>
    <wit-modal
        v-model="showModal"
        :title="blok.title"
        :variant="variant"
        disableDBlock
        :size="blok.size"
        customClass="wit-notification-modal"
        @input="emitInput"
    >
        <div v-html="richtext" />
    </wit-modal>
</template>

<script>
export default {
    props: ['blok', 'value', 'variant'],
    components: {
        WitModal: () => import('@/components/Modals/WitModal.vue'),
    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        richtext() {
            return this.blok.description
                ? '<div>' + this.$storyblok.richTextResolver.render(this.blok.description) + '</div>'
                : ''
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    created() {
        this.showModal = this.value
    },
    methods: {
        emitInput() {
            this.$emit('input', this.showModal)
        },
    },
}
</script>

<style lang="scss"></style>
